import React, { useState } from 'react';
import { useController } from './controller';
import * as S from './styles';
import * as icons from '@fortawesome/free-solid-svg-icons'
import * as iconBrands from '@fortawesome/free-brands-svg-icons'

const Home = (props: any) => {
    const controller = useController();

    return (
        <S.mainContainer>
            <S.header>
                <S.headerTitle>{'Arezdot'.toUpperCase()}</S.headerTitle>
                <S.buttonsContainer>
                    <S.button onClick={() => {
                        window.location.href = 'https://erp.arezdot.com/login';
                    }}>
                        Entrar
                    </S.button>
                    <S.button onClick={() => {
                        window.location.href = 'https://erp.arezdot.com/signup';
                    }}>
                        Cadastrar
                    </S.button>
                </S.buttonsContainer>
            </S.header>
            <S.body>
                <S.mainWithLogo>
                    <S.imageLogo>
                        <S.imgLogoYellowPart alt="Imagem de logo do Arezdot ERP" src="/app/images/logo/logo_arezdot_glow_only.png" />
                        <S.imgLogoDraw alt="Imagem de logo do Arezdot ERP" src="/app/images/logo/logo_arezdot_draw_only.png" />
                    </S.imageLogo>
                    <S.titles>
                        <S.title>{'Arezdot ERP'.toUpperCase()}</S.title>
                        <S.subtitle>Tecnologia avançada para uma gestão eficiente.</S.subtitle>
                    </S.titles>
                </S.mainWithLogo>
                <S.functionsContainer>
                    {controller.functionalities.map((item, index) => {
                        return (
                            <S.functionBit key={'functionality_' + index}>
                                {/* @ts-ignore */}
                                <S.functionIcon icon={icons[item.icon]} />
                                <S.functionTexts>
                                    <S.functionTitle>{item.title}</S.functionTitle>
                                    <S.functionDescription>
                                        {item.description}
                                    </S.functionDescription>
                                </S.functionTexts>
                            </S.functionBit>
                        )
                    })}
                </S.functionsContainer>
                <S.plansArea>
                    <S.plansTitle>Planos</S.plansTitle>
                    <S.plansPeriods>
                        <S.planPeriod selected={controller.planPeriod == 0} onClick={() => {controller.setPlanPeriod(0)}}>Mensal</S.planPeriod>
                        <S.planPeriod selected={controller.planPeriod == 1} onClick={() => {controller.setPlanPeriod(1)}}>Semestral (-5%)</S.planPeriod>
                        <S.planPeriod selected={controller.planPeriod == 2} onClick={() => {controller.setPlanPeriod(2)}}>Anual (-10%)</S.planPeriod>
                    </S.plansPeriods>
                    <S.plansContainer planShowing={controller.planShowing}>
                        <S.plansNextBackButton icon={icons.faChevronLeft} onClick={() => {
                            controller.changePlan('back');
                        }} />
                        <S.plansBits>
                            {controller.plans.map((item, index) => {
                                return (
                                    <S.planBit planSelected={controller.planShowing} showPlan={index === controller.planShowing} key={'plan_' + index} id={'plan_' + index} className={'plan_' + index} onClick={() => {
                                        window.location.href = 'https://erp.arezdot.com/signup';
                                    }}>
                                        <S.planTitle>{item.name}</S.planTitle>
                                        <S.planItems>
                                            {item.planItems.map((item, index) => {
                                                return (
                                                    <S.planItem key={'planItem_' + index}>{item}</S.planItem>
                                                )
                                            })}
                                        </S.planItems>
                                        <S.planValue>R$ {((item.value / 100) * controller.plansPeriods[controller.planPeriod].planValue).toFixed(2).toString().replace('.', ',')} / mês</S.planValue>
                                        <S.planButton className='hire_btn'>Contratar</S.planButton>
                                    </S.planBit>
                                )
                            })}
                        </S.plansBits>
                        <S.plansNextBackButton icon={icons.faChevronRight} onClick={() => {
                            controller.changePlan('next');
                        }} />
                    </S.plansContainer>
                </S.plansArea>
                <S.contactButton href='https://wa.me/5535998811741?text=Olá, gostaria de conhecer melhor o Arezdot ERP!'>
                    {/* @ts-ignore */}
                    <S.contactButtonIcon icon={iconBrands.faWhatsapp} />
                    <S.contactButtonText>Entre em contato</S.contactButtonText>
                </S.contactButton>
            </S.body>
        </S.mainContainer>
    )
}

export default Home;